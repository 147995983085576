* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

/* start login */
.div-login {
  border: 1px solid #0266ac;
  box-shadow: 3px 3px 3px #ddd;
  border-radius: 15px;
  background-color: #f6f6f6;
  width: 800px;
  height: 250px;
  text-align: center;
  margin: 10% auto;
  padding: 30px 0;
}
/* end login */

/* start header */
.header {
  height: 85px;
  padding: 10px 20px;
  border-bottom: 1px solid #888;
}
.header-logo {
  width: 65px;
  height: 65px;
}
.header-text-blue {
  font-size: 20px;
  font-weight: bold;
  color: #1867ac;
}
.header-text-orange {
  font-size: 18px;
  font-weight: bold;
  color: #ef5932;
}
.header-img {
  width: 45px;
  height: 57px;
}
.header-dev-icon-print {
  width: 30px;
  height: 35px;
  background-color: #ddd;
  padding: 5px;
  margin: 10px 0 10px 30px;
  cursor: pointer;
}
/* end header */

/* start content */
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  background-color: #4c89ba;
  color: #fff;
  padding: 5px 10px;
}
.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content p {
  height: 43px;
  width: 161px;
  background-color: #fff;
  padding: 10px;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.custom-modal {
  position: absolute;
  top: 200px;
  left: 25%;
  width: 50%;
  z-index: 1000;
}
.google-logout {
  width: 160px;
}
.div-padding-top {
  background-color: #ddd;
}
.div-background {
  padding: 50px 0 0 0;
  background-color: #fff;
  background-image: url("./assets/logo.png");
  /* background-size: 100vw; */
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 450px;
  opacity: 0.8;
}
/* start lap que dich */
.div-lap-que-dich {
  padding: 20px;
  background-color: #fff;
  opacity: 0.9;
}
.div-info {
  border: 1px solid #dc9c4c;
  border-radius: 10px;
  padding: 20px 40px;
  margin: 20px 18%;
  background-color: #f9fafc;
  width: 64%
}
.btn-blue {
  background-color: #1867ac;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  outline: none;
}
/* end lap que dich */

/* start luc hao */
.div-luc-hao {
  background-color: #f6f6f6;
  padding: 30px 20px;
  opacity: 0.9;
}
.div-detail-left {
  background-color: #af3f34;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 10px;
  height: 300px;
}
.div-detail-right {
  background-color: #af3f34;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 30px;
  height: 300px;
  padding: 135px 5px;
  cursor: pointer;
}
.div-detail-center {
  background-color: transparent;
  border: 1px solid #af3f34;
  height: 300px;
}
.div-que-do {
  height: 30px;
  background-color: #af3f34;
  border: 1px solid #ddd;
  color: #fff;
  padding: 2px 5px;
  text-align: left;
}
.div-que-do-nhat {
  height: 30px;
  background-color: #e7cfcd;
  border: 1px solid #ddd;
}
.div-border-right {
  border-right: 1px solid #af3f34;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.div-select-hao {
  display: none;
  position: absolute;
  right: -125px;
  top: -80px;
  z-index: 99;
  background-color: #14171d;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  text-align: left;
}
.icon-select-hao {
  position: absolute;
  left: -14px;
  top: 80px;
  color: #14171d;
  font-size: 30px;
}
/* end luc hao */

/* start ket luan phan tich */
.div-ket-luan-phan-tich {
  padding: 20px 25% 50px 25%;
  background-color: #fff;
  opacity: 0.9;
}
.textarea-ket-luan {
  border: 1px solid #dc9c4c;
  border-radius: 10px;
  padding: 10px;
}
.div-phan-tich-ky-thuat-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #ee5a35;
  width: 70px;
  height: min-content;
}
.div-phan-tich-ky-thuat-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #dc9c4c;
  padding: 10px;
  height: min-content;
}
/* end ket luan phan tich */

/* end content */

/* start footer */
.div-footer {
  background: #14171d none repeat scroll 0 0;
  color: #fff;
  padding-top: 5px;
  text-align: center;
  width: 100%;
  padding: 5px 20px;
}
.ads-show img {
  height: 80px;
  width: auto;
}
.social {
  padding: 10px 20px;
}
.social a {
  border: 1px solid;
  border-radius: 50%;
  color: #eee;
  display: inline-block;
  font-size: 17px;
  height: 40px;
  margin-left: 7px;
  padding-top: 8px;
  text-align: center;
  width: 40px;
}
.footer-bottom {
  float: left;
  margin: 10px 0;
  width: 100%;
}
.menu-footer-b {
  float: left;
  width: 885px;
}
.menu-footer-b ul.menu {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.menu-footer-b ul.menu li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 25%;
}
.menu-footer-b ul.menu li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.menu-footer-b ul.menu li a:hover {
  color: #c1282e;
}
.menu-footer-b ul.menu li ul {
  margin-top: 15px;
}
.menu-footer-b ul.menu li ul li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.menu-footer-b ul.menu li ul li a {
  text-transform: none;
  font-weight: normal;
}
/* end footer */



